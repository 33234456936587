<template>
  <div class="loginPage">
    <HeadInfo :isLogin="true" />
    <div class="messageParty d-flex justify-content-center align-items-center">
      <div style="margin-bottom: 3rem">
        <!-- <div class="text-center mb-3">
          <img
            src="@/assets/img/successfully-icon.png"
            class="successfullyPng"
            alt="success icon"
          />
        </div> -->
        <div v-html="description"></div>
        <div class="text-center mt-5">
          <k-button @click="returnToLogin"> returnToLogin </k-button>
        </div>
      </div>
    </div>
    <FooterInfo />
  </div>
</template>
<script>
import { getPageDesc } from "@/api/login";
import HeadInfo from "@/layout/headInfo";
import FooterInfo from "@/layout/footerInfo";
export default {
  components: {
    HeadInfo,
    FooterInfo,
  },
  data() {
    return {
      description: "",
      pageName: "ForgotPassword-SubmitSuccessful",
    };
  },
  methods: {
    returnToLogin() {
      this.$router.push({ name: "login" });
    },
  },
  mounted() {
    this.pageName = this.$route.query.pageName;
    getPageDesc(this.pageName).then((resp) => {
      this.description = resp.data.description;
    });
  },
};
</script>